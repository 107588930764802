import {Link} from 'gatsby'
import React from 'react'
import Img from "gatsby-image"
import {cn, buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import BlockText from './block-text'

import styles from './project-preview.module.css' 
import {responsiveTitle3} from './typography.module.css'

function ProjectPreview (props) {
  return (
      <div className={styles.caption}>
          <Img
            fluid={props.cover.asset.fluid}
            alt={props.cover.alt}
          />
        <h3>{props.title}</h3>
        <div className={styles.subcaption}>{props.client} – {props.year}</div>
      </div>
  )
}

export default ProjectPreview
