import {Link} from 'gatsby'
import React from 'react'
import ProjectPreview from './project-preview'

import Masonry from 'react-masonry-component'

import './project-preview-grid-masonry.css'

const masonryOptions = {
  columnWidth: '.grid-sizer',
  gutter: '.gutter-sizer',
  itemSelector: '.griditem',
  transitionDuration: '0.1',
  percentPosition: 'true',
  // className: 'masonry-container',
  // initLayout: 'false',
  // updateOnEachImageLoad: 'true'
};

function ProjectPreviewGrid (props) {
  return (
    <Masonry
      options={masonryOptions}
      className={'masonry-container'}
    >
    <div class="grid-sizer"></div>
    <div class="gutter-sizer"></div>
    {props.nodes &&
      props.nodes.map(node => (
        
        <div className='griditem' key={node.id}>
          <ProjectPreview
            {...node}
          />
        </div>
      ))}
  </Masonry>
  )
}

ProjectPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default ProjectPreviewGrid
